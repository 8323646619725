<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="margin-top: 80px;">

			<a-col :span="24" :md="18" :lg="18" :xl="{span: 14, offset: 0}" class="col-form pt-20 mt-20">
				
				<a-card :bordered="false" class="card-info px-0 mx-0 pt-20">
					<a-row :gutter="[24,24]">
						
						<a-col type="flex" :span="24" :md="12" :lg="12" align="middle" class="text-center  justify-items-center mt-20 pt-20">
							<img src="../../public/images/person_studying.png" alt="" width="100%" style="border-radius: 20px;"> 
						</a-col>
						
						<a-col :span="24" :md="12" :lg="12">
							<a-row class="text-center">
								<a-col :lg="24">
									<img src="../../public/images/icon.png" alt="" width="80px" height="80px" style="border-radius: 20px;"> 
								</a-col>
							</a-row>
							<h3 class="mb-0 mt-10 text-center">Welcome Back!</h3>
							<p class="text-muted text-center mt-0 pt-0">Login to continue</p>

							<a-alert
								v-if="error.show"
								:message="error.message"
								type="error"
								closable
								:after-close="handleClose"
								show-icon 
							/>

							<a-form
								id="components-form-demo-normal-login"
								:form="form"
								class="login-form"
								@submit="handleSubmit"
								:hideRequiredMark="true">
								<a-input-group>
									<a-row :gutter="24">
										<a-col :span="5" class="w-100 pr-0 mr-0">
											<a-form-item class="mb-10 w-100" label="Username" :colon="false">
												<vue-country-code
													style="height: 40px; width: 100%; margin-top: 4px;"
													@onSelect="onSelectCountry"
													:preferredCountries="['tz', 'ke', 'ug', 'za']"
													defaultCountry="tz">
												</vue-country-code>
											</a-form-item>
										</a-col>
										<a-col :span="19" class="ml-0 pl-1">
											<a-form-item class="mb-10" :colon="false">
												<a-input 
													style="margin-top: 32px; width: 100%;"
													v-decorator="[
													'username',
													{ rules: [{ required: true, message: 'Please input your username!' }] },
													]" placeholder="Email or Phone number" />
											</a-form-item>
										</a-col>
									</a-row>
									
								</a-input-group>
								
								<a-form-item class="mb-5" label="Password" :colon="false">
									<a-input
									v-decorator="[
									'password',
									{ rules: [{ required: true, message: 'Please input your password!' }] },
									]" type="password" placeholder="Password" />
								</a-form-item>

								<a-form-item class="mt-2">
									<a-button id="signinBtn" :loading="isLoading" type="silabu" block html-type="submit" class="login-form-button mt-10 text-white" >
										SIGN IN
									</a-button>
								</a-form-item>

								<a-row :gutters="24" class="pb-5">
									<a-col :span="24" class="text-center pb-5">
										<a class="" style="text-decoration: none !important; color: #632163;" :href="`/#/forgot-password`">
											<span>Forgot password?</span>
										</a>
									</a-col>
								</a-row>
							</a-form>
						</a-col>
					</a-row>
				</a-card>
			</a-col>


		</a-row>
		
	</div>
</template>

<script>
	import VueCountryCode from "vue-country-code-select";
	export default ({
		components: {
			VueCountryCode
		},

		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				error: {
					message: null,
					show: false,
				},

				countryCode: '255',

				isLoading: false,
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		created() {
		},
		methods: {

			onSelectCountry({name, iso2, dialCode}) {
				this.countryCode = dialCode;
			},

			handleClose() {
				this.error.show = false;
			},

			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						this.error.show = false;
						this.error.message = null;
						this.isLoading = true;

						const constUsername = values.username;
						let username = '';
						const password = values.password;

						console.log(constUsername.substring(0, 4));

						if(!constUsername.includes("@")){

							if(constUsername.substring(0, 4).includes(this.countryCode) && !constUsername.includes("+")) {
								username = "+" + constUsername;
							}

							else if(constUsername.substring(0, 4).includes(this.countryCode) && constUsername.includes("+")) {
								username =  constUsername;
							}

							else if(constUsername[0] == "0" || constUsername[0] == 0) {
								username = "+" + this.countryCode + "" + constUsername.substring(1)
							}

							else if(constUsername[0] != "0" || constUsername[0] != 0) {
								username = "+" + this.countryCode + "" + constUsername
							}

							else {
								username = constUsername;
							}

						}else{
							username = constUsername;
						}

						let url = `${this.$BACKEND_URL}/auth/login`;
						
						this.$AXIOS.post(url, { username, password }).then(async(response) => {
							if (response.status >= 200 && response.status < 210) {

								if(response.data.user.type && response.data.user.type == "institute") {
									await localStorage.setItem("user_token", response.data.token)
									await localStorage.setItem("user_details", JSON.stringify(response.data.user))
									await localStorage.setItem("institution_details", JSON.stringify(response.data.profile.institution))

									this.isLoading = false;
									// let url = await window.localStorage.getItem("previousUrl")

									// if(url != null && url.includes('class')) {
									// 	this.$router.replace(url)
									// }else{
										this.$router.push(`/dashboard`);
									// }
								}else{
									this.error.message = "You are not authorized to this site";
									this.error.show = true
								}
								
							}

							this.isLoading = false
						}).catch((err) => {
							console.log(err)
							this.isLoading = false;
							
							this.error.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
							this.error.show = true
						});
					}
				});
			},
		},
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	#signinBtn, #signinBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
</style>
